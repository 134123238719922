import Vue from 'vue';
import mainPage from "@/components/main-page/main-page.vue";
import "./assets/less/main.less";
import '@babel/polyfill';

import $ from 'jquery';
Vue.config.productionTip = false;

$("body").append("<div id='icookie'><div>")
var vm = new Vue({
    render(h) {
        return h(mainPage, {props: {}, ref: 'CookieDeisclamer'});
    },
    methods: {}
}).$mount('#icookie');





