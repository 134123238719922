
























































































































































import Vue from "vue";
import {Component, Watch, Emit} from "vue-property-decorator";
import startPage from "@/components/start-page/start-page.vue";
import {ToggleButton} from 'vue-js-toggle-button'
import {getCookies, getCookiesTrans} from "@/services/webclient/webclient";
import $ from 'jquery';

@Component({
    components: {
        startPage,
        ToggleButton
    }
})
export default class mainPage extends Vue {
    private text = {
        accept_all_text: "",
        accept_cookie_text: "",
        data_handling_text: "",
        data_handling_url: "",
        save_setting_text: "",
        setting_cookie_text: "",
        setting_functional_desc_text: "",
        setting_functional_title_text: "",
        setting_performance_desc_text: "",
        setting_performance_title_text: "",
        setting_required_desc_text: "",
        setting_required_title_text: "",
        setting_start_desc_text: "",
        setting_start_title_text: "",
        setting_targeting_desc_text: "",
        setting_targeting_title_text: "",
        start_desc: "",
        template: "",
    }

    isOpenSettings = false;
    isAdminEnabled = false;
    isOpenStart = true;
    isOpenCookieConsent = false;
    settingViewId = 0;
    changeView = 0;

    defaultSetting = {
        cookie_0: true,
        cookie_1: true,
        cookie_2: true,
        cookie_3: false
    };
    loadedCookieType = {
        type_0: false,
        type_1: false,
        type_2: false,
        type_3: false
    };

    mounted() {
        let hostName = window.location.hostname.replace('www.', '');

        getCookiesTrans(hostName).then((data) => {
                this.text = data;
                if (data.status == "1") {
                    this.isAdminEnabled = true;
                }
                this.initCookie();
            }
        )
    }

    initCookie() {

        let cookiePolicy = this.getCookie('cookie_policy');
        if (cookiePolicy.length > 0) {
            this.isOpenCookieConsent = false;
            this.defaultSetting.cookie_0 = cookiePolicy.indexOf('C0%1') != -1 ? true : false;
            this.defaultSetting.cookie_1 = cookiePolicy.indexOf('C1%1') != -1 ? true : false;
            this.defaultSetting.cookie_2 = cookiePolicy.indexOf('C2%1') != -1 ? true : false;
            this.defaultSetting.cookie_3 = cookiePolicy.indexOf('C3%1') != -1 ? true : false;
            if (this.defaultSetting.cookie_2 == false) {
                this.isOpenCookieConsent = true;
            }
            this.getScripts(true, true);
        } else if (this.isAdminEnabled) {
            this.isOpenCookieConsent = true;
            this.getDefaultScripts();
        }
        if (!this.isAdminEnabled){
            if(this.getCookie("cookieAccept").length>0){
                $(".cookie").hide();
            }else{
                $(".cookie").show();
            }
        }
    }

    goToSetting() {
        this.isOpenSettings = true;
        this.isOpenStart = false;
        this.changeView = this.changeView + 1;
    }

    acceptAll() {

        this.defaultSetting = {
            cookie_0: true,
            cookie_1: true,
            cookie_2: true,
            cookie_3: true
        }
        this.setCookie('cookie_policy', this.createCookieText(), 365, '/');
        this.isOpenCookieConsent = false;
        this.getScripts(false, false);

    }

    openSetting() {
        this.isOpenCookieConsent = true;
    }

    saveOption() {
        this.setCookie('cookie_policy', this.createCookieText(), 365, '/');
        this.isOpenCookieConsent = false;
        this.getScripts(false, false);
    }

    createCookieText() {
        let text = "";

        if (this.defaultSetting.cookie_0) {
            text += "C0%1_";
        } else {
            text += "C0%0_";
        }
        if (this.defaultSetting.cookie_1) {
            text += "C1%1_";
        } else {
            text += "C1%0_";
        }
        if (this.defaultSetting.cookie_2) {
            text += "C2%1_";
        } else {
            text += "C2%0_";
        }
        if (this.defaultSetting.cookie_3) {
            text += "C3%1_";
        } else {
            text += "C3%0_";
        }
        return text;
    }

    setCookie(cname, cvalue, exdays, pathname) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires + '; path=' + pathname;
    }

    getCookie(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return '';
    }

    getScripts(isDelayed = false, isEnabledDefault = true) {

        let hostName = window.location.hostname.replace('www.', '');
        let fromdata = new FormData();
        fromdata.append('cookie_0', this.defaultSetting.cookie_0 && isEnabledDefault ? 'true' : 'false');
        fromdata.append('cookie_1', this.defaultSetting.cookie_1 ? 'true' : 'false');
        fromdata.append('cookie_2', this.defaultSetting.cookie_2 ? 'true' : 'false');
        fromdata.append('cookie_3', this.defaultSetting.cookie_3 ? 'true' : 'false');
        if(isDelayed){
            setTimeout(()=>{
                getCookies(fromdata, hostName).then(data => {
                    for (let i = 0; i < data.length; i++) {
                        try {
                            $("body").append(data[i].script)
                        } catch (e) {

                        }
                    }
                })
            },1000)

        }else{
            getCookies(fromdata, hostName).then(data => {
                for (let i = 0; i < data.length; i++) {
                    try {
                        $("body").append(data[i].script)
                    } catch (e) {

                    }
                }
            })
        }
    }
    getDefaultScripts() {
        let hostName = window.location.hostname.replace('www.', '');
        let fromdata = new FormData();
        fromdata.append('cookie_0', 'true');
        fromdata.append('cookie_1', 'false');
        fromdata.append('cookie_2', 'false');
        fromdata.append('cookie_3', 'false');

        getCookies(fromdata, hostName).then(data => {
            for (let i = 0; i < data.length; i++) {
                try {
                    $("body").append(data[i].script)
                } catch (e) {

                }
            }
        })
    }

    reszletek() {
        $(".cookie-tooltip").show();
    }

    bezar() {
        $(".cookie-tooltip").hide();
    }

    elfogad() {
        $(".cookie").hide();
        this.setCookie("cookieAccept", "true", 365, "/");
    }


}


