import $ from 'jquery'

export const getCookies = (fromdata, hostName) => {
    // @ts-ignore
    return $.ajax({
        method: "POST",
        url: "https://cookie.infotipp.hu/api/getCookieScripts/"+hostName,
        data: fromdata,
        cache: false,
        contentType: false,
        processData: false
    });
};

export const getCookiesTrans = (hostName) => {
    // @ts-ignore
    return $.ajax({
        method: "GET",
        url: "https://cookie.infotipp.hu/api/getCookieTrans/"+hostName,
        cache: false,
        contentType: false,
        processData: false
    });
};

