








import Vue from "vue";
import {Component, Watch, Emit, Prop} from "vue-property-decorator";
import "./start-page.less";

@Component({
    components: {}
})
export default class startPage extends Vue {
    @Prop() startContent!: string;
    @Prop() startLink!: string;
    @Prop() startUrl!: string;


    mounted() {
    }



}
